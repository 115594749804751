import React from "react";
import Feedback from "./Feedback";


const Question = ({ item, index, handleOptionSelect, showFeedback, isCorrect, correctAnswers, selectedOptions }) => {
    return (
        <div className="vertical v-l constrained-width question-component">
          
          <div className="vertical v-m ">
            <div className="label label-dark">
            <img src={require(`../images/icons/lucide_dumbbell-white.svg`).default} alt="option-description"/>
              Practice
             
            </div>
            <h6>{item.question}</h6>
          </div>
        
  
          <div className={`h-l  mobile-vertical v-m mobile-start-cross-axis"  ${item.imageDesktopLarge ? 'vertical imageDesktopLarge' : 'horizontal'}`}>
            
            {item.image && (
              <div className="vertical v-s fill">
                <div className="question-image-wrapper">
                  <img src={require(`../images/${item.image}`)} />
                </div>
                {item.imageProduct && (
                  <div className="horizontal h-s">
                      <img className="product-img" src={require(`../images/${item.imageProductLogo}`)} />
                      <a className="source-link" href={item.imageProductSrc}  target="_blank" >{item.imageProduct}</a>
                  </div>
                )}
              </div>
            )}
            
            

            <ul className="fill vertical v-s question-options">
              {item.options.map((option, optionIndex) => (
                <li
                  key={optionIndex}
                  onClick={() => handleOptionSelect(option, item.correctAnswer, index)}
                
                  className={
                    showFeedback[index] && option === correctAnswers[index] ? 'correct': 
                    showFeedback[index] && option === selectedOptions[index] && !isCorrect[index] ? 'incorrect': 
                    showFeedback[index] ? 'feedback': 
                    'no-feedback'
                  }
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
       

        {showFeedback[index] && (
            <Feedback isCorrect={isCorrect[index]} explanation={item.explanation} explanationImg={item.explanationImg} explanationImgSrc={item.explanationImgSrc} explanationImgProduct={item.explanationImgProduct} explanationImgProductLogo={item.explanationImgProductLogo} />
        )}
      </div>
    )
}

export default Question