import React, { useEffect } from 'react';
import EmailCapture from './components/EmailCapture';
import LessonThumbnail from './components/LessonThumbnail';
import Logo from './components/Logo';


const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="vertical v-huge m-v-xl">
            <div className="hero">
                <div className="hero-inner constrained-width vertical v-l m-v-m center-cross-axis">
    
                    <Logo />

                    <div className='vertical'>
                      
                        <div id="crossed-out-text">
                            <h2 className='text-center' id="crossed-out-text">Boring design articles</h2>
                            <img id='line' src={require(`./images/line.svg`).default} />
                        </div>
                        
                        <h2 className='text-center'>Interactive design lessons</h2>
                    </div>
                    
                
                    <div className="vertical v-s center-cross-axis">
                        <p className='text-center'>Get notified each time a new lessons arrives.</p>
                        <EmailCapture></EmailCapture>
                    </div>
                </div>
            </div>

            <div className="vertical v-l less-constrained-width">
                <div className="flex-wrap  v-l m-v-m h-m">


                    <LessonThumbnail link="/progressive-disc" thumbnailImage="progressive-disc-cover.png" title="Progressive Disclosure" duration="4" />
                    <LessonThumbnail link="/8pt-system" thumbnailImage="space-cover.png" title="8pt System" duration="3" />
                    <LessonThumbnail link="/icon-lesson" thumbnailImage="icons-cover.png" title="Icon Design Principles" duration="4" />
                    <LessonThumbnail link="/colour-lesson" thumbnailImage="colour-cover.png" title="Creating Effective UI Colour Palette" duration="10" />
                    <LessonThumbnail link="/typography-lesson" thumbnailImage="typography-cover.png" title="Understanding Typography Styles" duration="6" />
                </div>
               
            </div>

          

            <footer className='constrained-width center-main-axis'>
                <p className='text-center'>Designed with coffee and love by <a className='link' href='https://www.linkedin.com/in/maksim-karacun/' target='_blank'>Max</a></p>
            </footer>

        </div>
    )
}

export default HomePage;