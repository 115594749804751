import React from "react";
import celebrationSound from '../audio/celebration-sound.mp3';
import EmailCapture from './EmailCapture';
import UserFeedback from "./UserFeedback";


const Summary = ({item , lessonName}) => {
    const celebration = new Audio(celebrationSound);
    celebration.play();

    return (
        <div className="vertical v-xl  mobile-v-padding-m v-padding-xl  border-radius grey-surface constrained-width">
            <div className="vertical v-s ">

                <img src={require(`../images/trophy.png`)} className="size-big" alt="option-description"/>

                <h4 className="success-container">Lesson Completed!</h4>
            </div>
            <div className="vertical v-m">
                <p>Key insights from this lesson:</p>
                <ul className="vertical v-m">
                    {item.keyInsights.map((keyInsight ,index) => (
                       
                        <li className="horizontal h-s start-cross-axis grey-surface-100 h-padding-m v-padding-s border-radius" key={index}>
                            <img src={require(`../images/icons/check.svg`).default}/>
                            <p>{keyInsight}</p>
                        </li>
                       
                    ))}
                </ul>
            </div>

            <UserFeedback lessonName={lessonName} />
          


        </div>
    )
}

export default Summary