import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Quiz from './Quiz';
import TypoQuizData from './quizData/typoQuizData';
import ColourQuizData from './quizData/colourQuizData';
import IconQuizData from './quizData/iconQuizData';
import EightPtSystemQuizData from './quizData/eightptsystemQuizData';
import ProgressiveDiscQuizData from './quizData/progressiveDiscQuizData';
import "./style.css";


function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />}  />
        <Route path="/typography-lesson" element={<Quiz quizData={TypoQuizData} lessonId="typogrpahy-lesson"/>}  />
        <Route path="/colour-lesson" element={<Quiz quizData={ColourQuizData} lessonId="colour-lesson"/>}  />
        <Route path="/icon-lesson" element={<Quiz quizData={IconQuizData} lessonId="icon-lesson"/> }  />
        <Route path="/8pt-system" element={<Quiz quizData={EightPtSystemQuizData} lessonId="eight-pt-system"/>}  />
        <Route path="/progressive-disc" element={<Quiz quizData={ProgressiveDiscQuizData} lessonId="progressive-disc"/>}  />
      </Routes>
    </Router>
  )
}
export default App;