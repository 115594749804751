import React from "react";
import Feedback from "./Feedback";



const QuestionImage = ({ item, index, handleOptionSelect, showFeedback, isCorrect, correctAnswers, selectedOptions }) => {
  // Determine the class name based on the number of options
  const optionsClass = item.options.length === 2 ? 'two-options' : 'more-options';

    return (
        <div className="vertical v-l less-constrained-width question-image-component">
          <div className="vertical v-m constrained-width">
            <div className="label label-dark">
              <img src={require(`../images/icons/lucide_dumbbell-white.svg`).default} alt="option-description"/>
                Practice
         
            </div>
            <h6>{item.question}</h6>
        </div>
         
          <ul className={`image-question-options ${optionsClass}`}>
            {item.options.map((option, optionIndex) => (
              <li
                key={optionIndex}
                onClick={() => handleOptionSelect(option, item.correctAnswer, index)}
                
                className={ 
                  showFeedback[index] && option === correctAnswers[index] ? 'correct': 
                  showFeedback[index] && option === selectedOptions[index] && !isCorrect[index] ? 'incorrect': 
                  showFeedback[index] ? 'feedback': 
                  'no-feedback'
                }
              >
                  <img src={require(`../images/${option}`)} alt="option-description" />
              </li>

            ))}
          </ul>
          {showFeedback[index] && (

              <Feedback isCorrect={isCorrect[index]} explanation={item.explanation} />
            
          )}
      </div>
    )
}

export default QuestionImage