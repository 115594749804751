

    const typefaceStylesTip = (
        <div className='vertical v-l'>
          <div className='horizontal h-m flex-wrap'>
            <div className='vertical v-s'>
              <img src={require(`../images/typo-lesson-images/typeface-style-01.png`)} alt="option-description" className='size-huge border border-radius'></img>
              <p>Serif</p>
            </div>
            <div className='vertical v-s'>
              <img src={require(`../images/typo-lesson-images/typeface-style-02.png`)} alt="option-description" className='size-huge border border-radius'></img>
              <p>Sans Serif</p>
            </div>
            <div className='vertical v-s'>
              <img src={require(`../images/typo-lesson-images/typeface-style-03.png`)} alt="option-description" className='size-huge border border-radius'></img>
              <p>Handwritten</p>
            </div>
            <div className='vertical v-s'>
              <img src={require(`../images/typo-lesson-images/typeface-style-04.png`)} alt="option-description" className='size-huge border border-radius'></img>
              <p>Display</p>
            </div>
            <div className='vertical v-s'>
              <img src={require(`../images/typo-lesson-images/typeface-style-05.png`)} alt="option-description" className='size-huge border border-radius'></img>
              <p>Monospaced</p>
            </div>
          </div>
    
        </div>
      )
      const typefaceEmotionsTable = (
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Emotions & Feelings</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Serif</td>
                <td>Tradition, elegance, professionalism, authority</td>
              </tr>
              <tr>
                <td>Sans Serif</td>
                <td>Modernity, simplicity, clarity, objectivity</td>
              </tr>
              <tr>
                <td>Monospaced</td>
                <td>Stability, reliability, retro, technical</td>
              </tr>
              <tr>
                <td>Script</td>
                <td>Personal, human, creativity</td>
              </tr>
              <tr>
                <td>Display</td>
                <td>Playfulness, uniqueness, novelty, creativity</td>
              </tr>
            </tbody>
          </table>
        </div>
      
      )
      const typeEmotionPairs = [
        { id: 1, item: 'Serif', img:'', pairId: 1, column: 1 },
        { id: 2, item: 'Tradition, elegance', img: '', pairId: 1, column: 2 },
        { id: 3, item: 'Sans Serif', img:'', pairId: 2, column: 1 },
        { id: 4, item: 'Modernity, simplicity',img: '', pairId: 2, column: 2 },
        { id: 5, item: 'Monospaced',  pairId: 3, img:'', column: 1 },
        { id: 6, item: 'Retro, Technical', img: '', pairId: 3, column: 2 },
        { id: 7, item: 'Script',  pairId: 4, img:'', column: 1 },
        { id: 8, item: 'Personal, human', img: '', pairId: 4, column: 2 },
        { id: 8, item: 'Display',  pairId: 5, img:'', column: 1 },
        { id: 9, item: 'Uniqueness, creativity', img: '', pairId: 5, column: 2 }
      ];
      
    const typoQuizData = [
        {
          type: 'intro',
          image: 'covers/typography-cover.png',
          title: 'Understanding Typography Styles',
          keyInsights: ['Common typography styles.', 'The link between they typography style and the emotions it evokes.'],
        },
        {
          type: 'info',
          image: '',
          htmlContent: typefaceStylesTip,
          text: 'There are several common Typeface styles.',
        },
        {
          type: 'question',
          question: 'Which typeface style is this?',
          image: 'typo-lesson-images/type-face-serif.png',
          options: ['Serif', 'Sans Serif', 'Monospaced', 'Script'],
          correctAnswer: 'Serif',
          explanationImg: 'typo-lesson-images/serif-exaplanation.png',
          explanationImgSrc: 'https://logo.com/blog/best-serif-fonts',
          explanation: "Serif typefaces are fonts that have small lines or strokes added to the ends of the main strokes of letters, which can make them look more traditional and easier to read in printed text."
        },
        {
          type: 'question',
          question: 'Which typeface style is this?',
          image: 'typo-lesson-images/type-face-sans-serif.png',
          options: ['Serif', 'Sans Serif', 'Display', 'Monospaced'],
          correctAnswer: 'Sans Serif',
          explanationImg: '',
          explanation: "Sans serif typefaces are fonts that don't have those small lines or strokes at the ends of the main strokes of letters, often giving them a more modern and clean appearance."
        },
        {
          type: 'question',
          question: 'Which typeface style is this?',
          image: 'typo-lesson-images/type-face-script.png',
          options: ['Handwritten', 'Sans Serif', 'Serif', 'Display'],
          correctAnswer: 'Handwritten',
          explanation: "Handwritten typefaces are fonts designed to mimic the appearance of handwriting, adding a personal touch to digital text."
        },
        {
          type: 'question',
          question: 'Which typeface style is used for The New Yorker article titles?',
          image: 'typo-lesson-images/new-yorker.png',
          options: ['Monospaced','Display', 'Sans Serif', 'Serif', ],
          correctAnswer: 'Serif',
          explanation: "The font used by the New Yorker print magazine for its body text is called Adobe Caslon. It's a serif font designed by William Caslon in the 18th century and is known for its elegant and classic appearance."
        },
        {
          type: 'question',
          question: 'Which typeface style is this?',
          image: 'typo-lesson-images/type-face-mono.png',
          options: ['Serif', 'Monospaced', 'Sans Serif', 'Script'],
          correctAnswer: 'Monospaced',
          explanationImg: 'typo-lesson-images/monospaced.png',
          explanationImgSrc: 'https://en.wikipedia.org/wiki/Monospaced_font',
          explanation: "Monospaced typefaces are fonts in which each character occupies the same amount of horizontal space."
        },
        {
          type: 'question',
          question: 'Which typeface style is used in Apple store website?',
          image: 'typo-lesson-images/apple-typeface.png',
          options: ['Sans Serif', 'Monospaced','Serif','Display'  ],
          correctAnswer: 'Sans Serif',
          explanation: "The font used by Apple is called SF Pro Display, which is a sans serif typeface, specifically designed for digital interfaces, focusing on optimal clarity and readability on screens of various sizes and resolutions."
        },
        {
          type: 'info',
          variant: 'fun-fact' ,
          image: 'typo-lesson-images/quick-brown-fox.png',
          text: "This is a pangram, a sentence that contains every letter of the alphabet at least once. It's commonly used for font testing and demonstration because it showcases how each letter looks in a particular typeface.",
        },
        {
          type: 'question',
          question: 'What do you think is the most popular typeface used on the web as of 2024?',
          options: ['Times New Roman', 'Helvetica', 'Arial', 'Comic Sans'],
          correctAnswer: 'Helvetica',
          explanationImg: 'typo-lesson-images/type-face-helvetica.png',
          explanation: "Helvetica is the most popular typeface in the world, used by 19% of all websites. Arial, used by approximately 17% of all websites, is the 2nd most popular font."
        },
        {
          type: 'info',
          image: 'typo-lesson-images/type-face-experiment.png',
          htmlContent: '',
          text: "In 2012, Errol Morris conducted a study to explore how fonts influence people's perceptions. He asked online participants if they agreed with the statement 'We are living in an era of unprecedented safety,' presenting it in various fonts including Baskerville, Helvetica, and Comic Sans."
        },
        {
          type: 'question',
          question: 'Can you guess statement written in which typeface had the highest rate of agreements?',
          options: ['Baskerville', 'Helvetica', 'Comic Sans', 'No difference'],
          correctAnswer: 'Baskerville',
          explanationImg: '',
          explanation: "Those who saw the statement in Baskerville were more likely to agree with it. This shows that typeface we use can affect how we think."
        },
        {
          type: 'question',
          question: 'Can you guess the typical emotion evoked by Serif typefaces?',
          image: 'typo-lesson-images/type-face-serif-lora.png',
          options: ['Modernity', 'Tradition', 'Creativity', 'Playfulness'],
          correctAnswer: 'Tradition',
          explanationImg: '',
          explanation: "Serif typefaces typically evoke a sense of tradition, formality, and elegance."
        },

        {
          type: 'info',
          image: '',
          htmlContent: typefaceEmotionsTable,
          text: 'Different typeface styles generally evoke distinct emotions and feelings.',
        },
        {
          type: 'question-matching-pairs',
          pairs: typeEmotionPairs,
          explanation: ''
        },
        {
          type: 'question-image',
          question: 'Which of the typefaces in your opinion would work best for a law consultant firm?',
          options: ['typo-lesson-images/legal-option-1.png', 'typo-lesson-images/legal-option-2.png', 'typo-lesson-images/legal-option-3.png', 'typo-lesson-images/legal-option-4.png'],
          correctAnswer: 'typo-lesson-images/legal-option-1.png',
          explanation: "Serif typefaces would probably be the best option here, as they are generally associated with professionalism, tradition, and authority, which is likely something that a law consultant would want to be associated with."
        },
        {
          type: 'info',
          variant: 'tip' ,
          image: '',
          text: 'When selecting a typeface, ensure it aligns with the mood or impression you intend to convey.',
        },
        {
          type: 'question-image',
          question: 'Which of the typefaces in your opinion would work best for a tech company?',
          options: ['typo-lesson-images/tech-option-1.png', 'typo-lesson-images/tech-option-2.png', 'typo-lesson-images/tech-option-3.png', 'typo-lesson-images/tech-option-4.png'],
          correctAnswer: 'typo-lesson-images/tech-option-4.png',
          explanation: "Sans-serif typefaces are commonly used for tech startups, as they reflect modernity, simplicity, and innovation, which are often core values in the tech industry."
        },
    
        {
          type: 'info',
          variant: 'fun-fact' ,
          image: 'typo-lesson-images/trojan-typeface.png',
          text: "The oldest known typeface still in use today is Trajan, which dates back to around 113 AD. It was inspired by the letterforms used in inscriptions on the base of the Trajan's Column in Rome and is often associated with classic elegance and authority.",
        },
        {
          type: 'summary',
          keyInsights: ['There are several common typography styles e.g. serif, sans serif etc.', 'There is a link between they typography style and the emotions it evokes e.g. serif typefaces generally is associated with tradition and elegance.'],
        }
        
      ]


export default typoQuizData;