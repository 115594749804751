import React from "react";

const Info = ({item}) => {
    return (
        <div  className={`constrained-width ${item.variant ? 'accent-info' : 'vertical v-m'}`}>
           
            {item.variant=== "tip" && (
                <div className="horizontal center-cross-axis h-s">
                    <img src={require(`../images/icons/bookmark-check.svg`).default}/>
                    <h6>Tip</h6>
                </div>
            )}
            {item.variant=== "fun-fact" && (
                <div className="horizontal center-cross-axis h-s">
                    <img src={require(`../images/icons/book-open-check.svg`).default}/>
                    <h6>Fun Fact</h6>
                </div>
            )}


           

            {item.image && (
                <div className="vertical v-s fill">
                    <img src={require(`../images/${item.image}`)} alt="option-description" className="border border-radius fill-75 mobile-fill"/>
                  {item.imageProduct && (
                    <div className="horizontal h-s">
                        <img className="product-img" src={require(`../images/${item.imageProductLogo}`)} />
                        <a className="source-link" href={item.imageProductSrc}  target="_blank" >{item.imageProduct}</a>
                    </div>
                  )}
                </div>
              )}
            {item.htmlContent && (item.htmlContent)}
            <p>{item.text}</p>
        </div>
    )
}

export default Info