import React, { useState, useEffect, useRef } from 'react';
import correctSound from '../audio/correct-sound.mp3';
import incorrectSound from '../audio/incorrect-sound.mp3';
import Feedback from './Feedback';


const shuffleArray = (array) => {
  let shuffledArray = array.slice(); // Make a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const MatchingPairs = ({ pairs, onAllPairsMatched, questionIndex, showFeedback, scrollToFeedback, explanation }) => {
  const [selectedPair, setSelectedPair] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [incorrectSelections, setIncorrectSelections] = useState([]);
  const [shuffledPairs, setShuffledPairs] = useState({ col1: [], col2: [] });
  const [hasImage, setHasImage] = useState(false);
  const itemsRef = useRef([]); // Create an array to hold references to each item
  const [maxHeight, setMaxHeight] = useState(0); // State to store the maximum height



  useEffect(() => {
    const column1 = shuffleArray(pairs.filter(item => item.column === 1));
    const column2 = shuffleArray(pairs.filter(item => item.column === 2));
    setShuffledPairs({ col1: column1, col2: column2 });

    const hasImageInPairs = pairs.some(item => item.img);
    setHasImage(hasImageInPairs);
  }, [pairs]);

  useEffect(() => {
    // Measure heights of all items and find the tallest one
    const adjustHeight = () => {
      const elements = document.querySelectorAll('#matching-option');
      let maxHeight = 0;

      elements.forEach((el) => {
        const height = el.getBoundingClientRect().height;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      elements.forEach((el) => {
        el.style.height = `${maxHeight}px`;
      });
    };

    adjustHeight();

    // Add event listener to adjust heights on window resize
    window.addEventListener('resize', adjustHeight);
    return () => window.removeEventListener('resize', adjustHeight);

  }, [shuffledPairs]);



  const correctAudio = new Audio(correctSound);
  const incorrectAudio = new Audio(incorrectSound);

  const handleItemClick = (item) => {
    if (matchedPairs.includes(item.pairId)) {
        return; // If the pair is already matched, do nothing
    }
    if (selectedPair.length === 0) {
      setSelectedPair([item]);
    } else if (selectedPair.length === 1 && selectedPair[0].column !== item.column) {
      const firstItem = selectedPair[0];
      if (firstItem.pairId === item.pairId && firstItem.id !== item.id) {
        
        correctAudio.play();
        const newMatchedPairs = [...matchedPairs, firstItem.pairId];
        setMatchedPairs(newMatchedPairs);
        setSelectedPair([]);
        if (newMatchedPairs.length === pairs.length / 2) {
          onAllPairsMatched(questionIndex);
          setTimeout(scrollToFeedback, 300);
        }
      } else {
        incorrectAudio.play();
        setSelectedPair([...selectedPair, item]);
        setIncorrectSelections([firstItem, item]);
        setTimeout(() => {
          setIncorrectSelections([]);
          setSelectedPair([]);
        }, 1000);
      }
    }
  };

  const isMatched = (item) => matchedPairs.includes(item.pairId);
  const isSelected = (item) => selectedPair.some(selected => selected.id === item.id);
  const isIncorrect = (item) => incorrectSelections.some(selected => selected.id === item.id);

  return (
    <div className="vertical v-l constrained-width question-component matching-pairs">
    <div className="vertical v-m">
            <div className="label label-dark">
            <img src={require(`../images/icons/lucide_dumbbell-white.svg`).default} alt="option-description"/>
              Practice
             
            </div>
            <h6>Select the matching pairs</h6>
          </div>
    <div className={`horizontal h-xl m-h-m ${hasImage ? 'option-img' : ''}`}>
      <ul className="fill vertical v-s question-options col-1">
        {shuffledPairs.col1.map((item) => (
          <li
            id="matching-option"
            key={item.id}
            onClick={() => handleItemClick(item)}
            className={`no-feedback horizontal center-cross-axis
              ${isMatched(item) ? 'correct' : ''} 
              ${isSelected(item) ? (isIncorrect(item) ? 'incorrect' : 'selected') : ''}`
            }
            
          >
            {item.img ? <img src={require(`../images/${item.img}`)} alt="" /> : item.item}
          </li>
        ))}
      </ul>
      <ul className="vertical v-s question-options fill col-2">
        {shuffledPairs.col2.map((item) => (
          <li
            id="matching-option"
            key={item.id}
            onClick={() => handleItemClick(item)}
            className={`no-feedback horizontal center-cross-axis
              ${isMatched(item) ? 'correct' : ''} 
              ${isSelected(item) ? (isIncorrect(item) ? 'incorrect' : 'selected') : ''}`}
          >
            {item.img ? <img src={require(`../images/${item.img}`)} alt="" /> : item.item}
          </li>
        ))}
      </ul>
      </div>
      {showFeedback[questionIndex] && (

        <Feedback isCorrect={true} explanation={explanation} />
      
        )}
     
    </div>
  );
};

export default MatchingPairs;