import React from "react";

const Feedback = ({isCorrect, explanation, explanationImg, explanationImgSrc, explanationImgProduct, explanationImgProductLogo}) => {
    return (
        <div className="vertical v-m feedback-container constrained-width">
            {isCorrect ? 
                <div className="horizontal h-s correct-feedback">
                    <img src={require(`../images/icons/circle-check-filled-green.svg`).default} />
                    <h6>That is right!</h6>
                </div> : 
                <div className="horizontal h-s incorrect-feedback">
                    <img src={require(`../images/icons/circle-cross-filled-red.svg`).default} />
                    <h6>Not quite!</h6>
                </div>
            }
            {explanationImg && (
                <div className="vertical v-s">
                    <img src={require(`../images/${explanationImg}`)} className="border border-radius fill-75 mobile-fill"/>

                    {explanationImgProduct && (
                        <div className="horizontal h-s">
                            <img className="product-img" src={require(`../images/${explanationImgProductLogo}`)} />
                            <a className="source-link" href={explanationImgSrc}  target="_blank" >{explanationImgProduct}</a>
                        </div>
                    )}
                    {explanationImgSrc && !explanationImgProduct && (
                        <a className="source-link" href={explanationImgSrc} target="_blank">Image Source</a>
                    )}
                    
                </div>
            )}
            <p>{explanation}</p>
        </div>
    )
}

export default Feedback