import {Link} from "react-router-dom";

const LessonThumbnail = ({title, thumbnailImage, duration, link}) => {

    const isLinkEmpty = !link;

    return (
    <Link to={link} className="fill lesson-card-outer" >
        <div className=" lesson-card-inner vertical mobile-horizontal fill  border-radius h-padding-l v-padding-l mobile-h-padding-m mobile-v-padding-xl v-m h-m m-h-l">
            <div  className={`lesson-thumbnail-cover-s  ${isLinkEmpty ? 'opacity-50' : ''}`}>
                <img src={require(`../images/covers/${thumbnailImage}`)} />
            </div>
          
            <div className="vertical v-s fill m-v-m">
                {isLinkEmpty && <span className="label">Coming Soon</span>}
                <h6 className={`${isLinkEmpty ? 'opacity-50' : ''}`} >{title}</h6>
                {!isLinkEmpty && (
                <div className={`horizontal h-s  ${isLinkEmpty ? 'opacity-50' : ''}`}>
                    <img src={require(`../images/icons/lucide_clock.svg`).default} />
                    <p>{`${duration} min`}</p>
                </div>
                )}
            </div>

        </div>
    </Link>
    )
}

export default LessonThumbnail