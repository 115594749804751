const colourGroupsTable = (
    <div className="vertical v-m">
        <p>Generally, UI colours can be divided into three most important groups:</p>

        <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td><strong>Accent colours</strong></td>
              <td>These are used to highlight or draw attention to important elements on the screen.</td>
            </tr>
            <tr>
              <td><strong>Neutral colours</strong></td>
              <td>These are more subdued and are often used for backgrounds, borders, text, and icons.</td>
            </tr>
            <tr>
              <td><strong>Semantic colours</strong></td>
              <td>These are used to convey meaning or status, such as success, warning, error, and information messages.</td>
            </tr>
  
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)
const accentGroupsTable = (
    <div className="vertical v-m">
        <p>Accent colours can be categorised  further into two sub-groups:</p>

        <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td><strong>Primary colours</strong></td>
              <td>These are utilised to direct users’ attention to the most critical elements in the UI, such as primary buttons.</td>
            </tr>
            <tr>
              <td><strong>Secondary colours</strong></td>
              <td>These are employed to highlight secondary elements like secondary buttons and selection controls.</td>
            </tr>
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)


const colourQuizData = [
    {
        type: 'intro',
        image: 'covers/colour-cover.png',
        title: 'Foundations of Effective UI Colour Palette',
        keyInsights: ['Different colour groups.', 'Why we need primary and secondary colours.', '"60-30-10" rule.', 'Why you need multiple shades of each colour.'],
    },
    {
        type: 'info',
        image: '',
        htmlContent: colourGroupsTable,
        text: '',
    },
    {
        type: 'question',
        question: 'What is the primary purpose of accent colours in UI design?',
        image: '',
        options: ['To serve as the main background colour', 'To direct focus towards important elements', 'These convey meaning or status'],
        correctAnswer: 'To direct focus towards important elements',
        explanationImg: 'colour/basecamp.png',
        explanationImgProduct: 'Basecamp',
        explanationImgProductLogo: 'colour/basecamp-logo.png',
        explanationImgSrc: 'https://basecamp.com/',
        explanation: "Here is an example from Basecamp of how they use their green accent colour to draw users' attention to the most important actions."
    },
    {
        type: 'question',
        question: 'What colour acts as the accent in this example?',
        image: 'colour/strava.png',
        imageDesktopLarge: true,
        imageProduct: 'Strava',
        imageProductSrc: 'https://www.strava.com/',
        imageProductLogo: 'colour/strava-logo.png',
        options: ['Beige', 'Black', 'Orange', 'Green'],
        correctAnswer: 'Orange',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: 'https://www.strava.com/',
        explanation: "Orange is an accent color of Strava, used to direct attention to crucial elements on the screen, such as the route and the 'Save Route' action."
    },

    {
        type: 'info',
        variant: 'tip' ,
        image: 'colour/brand-colour-brands.png',
        text: 'Accent colours are often referred to as brand colours. This is the most defining colour group, as users will often associate your product with that colour. Think of red for CocaCola, blue for Facebook, green for Starbucks etc.',
    },
    {
        type: 'question',
        question: 'What is NOT the common use case for neutral colour group?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Background', 'Borders', 'Icons', 'Buttons'],
        correctAnswer: 'Buttons',
        explanationImg: 'colour/neutral-colours-calendar.png',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Normally, accent colours are used for button elements, while neutral colours are more subtle and used for text, backgrounds, borders and icons."
    },

    {
        type: 'question',
        question: 'A red colour error message is an example of which colour group?',
        image: 'colour/airbnb-error.png',
        imageProduct: 'Airbnb',
        imageProductSrc: 'https://www.airbnb.com/',
        imageProductLogo: 'colour/airbnb-logo.png',
        imageDesktopLarge: true,
        options: ['Accent', 'Neutral', 'Structure', 'Semantic'],
        correctAnswer: 'Semantic',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Red colour is often used for warnings and is an example of a semantic colour group."
    },
    {
        type: 'question',
        question: 'What is the primary purpose of the semantic colours in UI design?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['To direct focus towards important elements', 'For visual decoration', 'To convey meaning or status'],
        correctAnswer: 'To convey meaning or status',
        explanationImg: 'colour/booking-error.png',
        explanationImgProduct: 'Booking',
        explanationImgProductLogo: 'colour/booking-logo.png',
        explanationImgSrc: 'https://www.booking.com/',
        explanation: "Semantic colours are used to convey meaning or status, such as success, warning, error, and information messages."
    },
    {
        type: 'question',
        question: 'Which semantic colour would work best to display success messages?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Blue', 'Green', 'Yellow', 'Black'],
        correctAnswer: 'Green',
        explanationImg: 'colour/duolingo-success.png',
        explanationImgProduct: 'Duolingo',
        explanationImgProductLogo: 'colour/duolingo-logo.png',
        explanationImgSrc: 'https://www.duolingo.com/',
        explanation: "Green is often the best choice as it's commonly associated with positive messages. For example, Duolingo app uses green to highlight when user has given a correct answer."
    },
    {
        type: 'question-image',
        question: 'Which option would work best to display a warning message?',
        options: ['colour/warning-option-1.png','colour/warning-option-2.png'],
        correctAnswer: 'colour/warning-option-1.png',
        explanation: "Yellow is often used to catch the user's attention and indicate that there is something they need to be cautious about or pay attention to. It's a colour that can signify warning without being as intense or alarming as red."
    },
    {
        type: 'info',
        image: '',
        htmlContent: accentGroupsTable,
        text: '',
    },
    {
        type: 'question',
        question: 'What is the primary colour in this example?',
        image: 'colour/eventbrite.png',
        imageProduct: 'Eventbrite',
        imageProductSrc: 'https://www.eventbrite.com/',
        imageProductLogo: 'colour/eventbrite-logo.png',
        imageDesktopLarge: true,
        options: ['White', 'Purple', 'Dark Orange', 'Black'],
        correctAnswer: 'Dark Orange',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Remember, primary colours are used to distinguish the most important elements in the UI. In this example, primary action is “Next” button and secondary action is the “Following” action."
    },
    {
        type: 'info',
        variant: 'fun-fact' ,
        image: 'colour/colour-white-symbolism.png',
        text: "Colors can have different meanings and symbolism in different cultures. For example, white is associated with purity in Western cultures but symbolises mourning in many Asian cultures.",
      },
      {
        type: 'question',
        question: ' What is the secondary colour in this example?',
        image: 'colour/angi.png',
        imageProduct: 'Angi',
        imageProductSrc: 'https://www.angi.com/',
        imageProductLogo: 'colour/angi-logo.png',
        imageDesktopLarge: true,
        options: ['White', 'Teal', 'Red', 'Black'],
        correctAnswer: 'Teal',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "In this case, 'Continue' is a primary action, and frequency selection is secondary. "
    },
    {
        type: 'info',
        variant: 'tip' ,
        image: 'colour/visual-hierarchy.png',
        text: 'Use accent colours purposefully to establish a clear visual hierarchy. Identify what are the most important element(s) on the screen and apply primary colour to distinguish it. For the second most important elements, apply secondary colour etc.',
    },
    {
        type: 'question',
        question: 'What principle should guide your approach when applying colour into your UI design?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Aesthetic enhancement', 'Establishing clear visual hierarchy'],
        correctAnswer: 'Establishing clear visual hierarchy',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Use accent colours intentionally to make things stand out. Pick out the most important elements on the screen and apply accent colours to make them pop."
    },
    {
        type: 'info',
        image: 'colour/60-30-10.png',
        htmlContent: '',
        text: 'The “60-30-10” rule states that neutral colour should take up 60% of your design, the secondary colour should take up 30%, while a primary colour should take up 10% of your design. Following this rule will help you to achieve a more balanced design and guide users attention to the most important elements effectively.',
    },
    {
        type: 'question',
        question: 'In the "60-30-10" rule, which colour should occupy the lowest percentage?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Primary', 'Secondary', 'Neutral', 'Semantic'],
        correctAnswer: 'Primary',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "You should use primary colour sparingly, to emphasise the most important elements on the screen."
    },
    {
        type: 'question-image',
        question: ' Which option demonstrates a better application of “60-30-10” rule?',
        options: ['colour/60-30-10-option-1.png','colour/60-30-10-option-2.png'],
        correctAnswer: 'colour/60-30-10-option-1.png',
        explanation: "In this example, approximately 60% of the space is occupied by neutral colors—white and grays—while around 30% is taken up by a secondary color, light purple, and the remaining 10% by the primary purple color. This creates a balanced design and establishes a clear visual hierarchy."
    },
    {
        type: 'info',
        variant: 'fun-fact' ,
        image: 'colour/60-30-10-interior-design.png',
        text: "The '60-30-10' rule isn't exclusive to UI design. It's widely applicable across various fields such as graphic design and interior design as this principle helps create balanced colour compositions.",
    },
    {
        type: 'question-image',
        question: 'Which of these represents a more practical UI colour palette? ',
        options: ['colour/pallette-option-1.png','colour/pallette-option-2.png'],
        correctAnswer: 'colour/pallette-option-1.png',
        explanation: "You will most likely need multiple shades of each colour."
    },
    {
        type: 'question',
        question: 'Which colour group most likely require more shades?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Primary', 'Neutral', 'Secondary', 'Semantic'],
        correctAnswer: 'Neutral',
        explanationImg: 'colour/neutral-multitude-shades.png',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Neutral colours often require a broader range of shades to accommodate various use cases, such as borders, typography colours, and background hues."
    },
    {
        type: 'info',
        variant: '' ,
        image: 'colour/primary-shades.png',
        text: "It is not only neutral colours that will require multiple shades. In your UI, you would probably need multiple shades for each of your accent and semantic colours too.",
    },
    {
        type: 'summary',
        keyInsights: ['Typically, UI colours can be categorised into three primary groups: neutral, accent, and semantic.', 'Accent colours are commonly further distinguished into primary and secondary colours.', 'Apply the "60-30-10" rule to create harmonious colour palette and achieve a clear visual hierarchy.', 'You will likely need multiple shades within each color group to accommodate a wide range of use cases.'],
      }
]

export default colourQuizData