import React from "react";


const Intro = ({item}) => {
    return(
        <div className="vertical v-m constrained-width">
            <img src={require(`../images/${item.image}`)} alt="option-description" className="border-radius size-huge" />
            <h3>{item.title}</h3>
            <div className="vertical v-l">
                <p>In this lesson, you will learn:</p>
                <ul className="vertical v-s">
                    {item.keyInsights.map((keyInsight ,index) => (
                       
                        <li className="horizontal h-s start-cross-axis grey-surface-100 h-padding-m v-padding-s border-radius " key={index}>
                            <img src={require(`../images/icons/check.svg`).default}/>
                            <p>{keyInsight}</p>
                        </li>
                       
                    ))}
                </ul>
            </div>
          
        </div>
    )
}

export default Intro