// Sample data
const iconStylePairs = [
  { id: 1, item: 'Filled', img:'', pairId: 1, column: 1 },
  { id: 2, item: '', img: 'icons-lesson/home-icon-1.png', pairId: 1, column: 2 },
  { id: 3, item: 'Outline', img:'', pairId: 2, column: 1 },
  { id: 4, item: '',img: 'icons-lesson/home-icon-2.png', pairId: 2, column: 2 },
  { id: 5, item: 'Coloured',  pairId: 3, img:'', column: 1 },
  { id: 6, item: '', img: 'icons-lesson/home-icon-3.png', pairId: 3, column: 2 },
];
const iconMeaningPairs = [
  { id: 1, item: 'Delete', img:'', pairId: 1, column: 1 },
  { id: 2, item: '', img: 'icons-lesson/icon-delete.png', pairId: 1, column: 2 },
  { id: 3, item: 'Edit', img:'', pairId: 2, column: 1 },
  { id: 4, item: '',img: 'icons-lesson/icon-edit.png', pairId: 2, column: 2 },
  { id: 5, item: 'Search',  pairId: 3, img:'', column: 1 },
  { id: 6, item: '', img: 'icons-lesson/icon-search.png', pairId: 3, column: 2 },
  { id: 7, item: 'Settings',  pairId: 4, img:'', column: 1 },
  { id: 8, item: '', img: 'icons-lesson/icon-settings.png', pairId: 4, column: 2 }
];


const iconQuizData = [
  {
    type: 'intro',
    image: 'covers/icons-cover.png',
    title: 'Icon Design Principles',
    keyInsights: ['The importance of using consistent icon styles and what they are.', 'What icon geometry is and why maintaining uniform icon geometry is essential.','How to handle universally recognised icons versus those that are not universally recognised.'],
  },



  {
        type: 'question',
        question: 'What is the issue with this icon set?',
        image: 'icons-lesson/icon-1.png',
        options: ['Inconsistent icon style', 'Inconsistent icon size', 'Unclear icon metaphors', 'No issues'],
        correctAnswer: 'Inconsistent icon style',
        explanationImg: 'icons-lesson/icon-1-expl.png',
        explanationImgSrc: '',
        explanation: "There are several icon styles such as outlined icons, filled icons, coloured icons, etc. Pick one icon style and be consistent with it throughout your interface."
    },

    {
        type: 'question-image',
        question: 'Which of the following icon sets is consistent in style?',
        options: ['icons-lesson/icon-set-option-1.png','icons-lesson/icon-set-option-2.png','icons-lesson/icon-set-option-3.png','icons-lesson/icon-set-option-4.png'],
        correctAnswer: 'icons-lesson/icon-set-option-4.png',
        explanation: "All of the icons in that icon set have the filled icon style."
    },
    {
      type: 'question-matching-pairs',
      pairs: iconStylePairs,
      explanation: ''
    },
  
    {
        type: 'question',
        question: 'Why is it important to maintain a consistent icon style throughout a user interface?',
        image: '',
        options: ['To improve usability', 'To ensure visual harmony and a consistent feel', 'To save time during the design process', 'To allow for different artistic expressions'],
        correctAnswer: 'To ensure visual harmony and a consistent feel',
        explanationImg: '',
        explanation: "Using the same icon style throughout the interface will create a cohesive feel and make your product look and feel more professionally designed."
      },
      {
        type: 'question',
        question: 'What is the issue with this icon set?',
        image: 'icons-lesson/icon-3.png',
        options: ['Inconsistent icon style', 'Inconsistent icon size', 'Inconsistent icon geometry'],
        correctAnswer: 'Inconsistent icon geometry',
        explanationImg: 'icons-lesson/icon-3-expl.png',
        explanation: "If some of your icons have crisp, sharp corners, then all of your other icons should too."
      },
      {
        type: 'question-image',
        question: ' Which of the following icon sets is consistent in icon geometry?',
        options: ['icons-lesson/icon-4-1.png','icons-lesson/icon-4-2.png','icons-lesson/icon-4-3.png','icons-lesson/icon-4-4.png'],
        correctAnswer: 'icons-lesson/icon-4-4.png',
        explanation: "Only in one icon set do all icons have consistent smooth corners. In the other options, it’s a mix."
    },
    {
        type: 'question',
        question: 'Why is it important to maintain a consistent icon geometry?',
        image: 'icons-lesson/icon-4-expl.png',
        options: ['To improve icon recognition', 'To ensure a harmonious, unified style', 'To improve usability'],
        correctAnswer: 'To ensure a harmonious, unified style',
        explanationImg: '',
        explanation: "Not only is it important to ensure that your icons are done in the same style, but also to follow the same icon geometry. This will create a cohesive feel and make your product look and feel more professionally designed."
      },
      {
        type: 'question-matching-pairs',
        pairs: iconMeaningPairs,
        explanation: ''
      },
     

      {
        type: 'info',
        image: 'icons-lesson/icon-7.png',
        text: 'There are quite a few icons that are universally recognisable and users will instantly recognise them and understand their meaning.',
      },
      {
        type: 'info',
        image: 'icons-lesson/icon-8.png',
        text: 'However, not all icons are universally recognisable. When using icons that are not universally recognisable, icon labels minimise ambiguity. Don’t assume that all users will immediately understand the purpose of an icon. When in doubt, include a label.',
      },
      {
        type: 'question-image',
        question: ' Which of the following icons would require a label?',
        options: ['icons-lesson/icon-9.1.png','icons-lesson/icon-9.2.png','icons-lesson/icon-9.3.png','icons-lesson/icon-9.4.png'],
        correctAnswer: 'icons-lesson/icon-9.2.png',
        explanation: "Out of the ones presented, only one icon would require a label the rest are universally recognisable and therefore, the label can be omitted."
    },
    {
      type: 'summary',
      keyInsights: ['Choose one icon style and apply it consistently across your interface.', 'Ensure all icons share the same geometry; for instance, if some icons have crisp, sharp corners, all should follow this style.', 'Universally recognizable icons do not need labels, whereas non-universally recognizable icons should have labels for clarity.'],
    }
]
export default iconQuizData