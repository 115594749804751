const progressiveDiscTypes = (
    <div className="vertical v-m">
        <p>There are different types of progressive disclosure.</p>

        <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>UI Pattern</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sequential disclosure</td>
              <td>Breaking complex tasks into manageable steps. Each step is revealed after the previous one is completed.</td>
              <td>Multi-step flow</td>
            </tr>
            <tr>
              <td>Conditional disclosure</td>
              <td>Hiding certain elements until the user needs them, based on their actions or input.</td>
              <td>Dynamic forms, displaying extra fields based on previous selections</td>
            </tr>
            <tr>
              <td>Hierarchical disclosure</td>
              <td>Structuring information in layers, allowing users to access more detailed levels as needed. </td>
              <td>Multi-level navigation, Tabs, Accordions </td>
            </tr>
            <tr>
              <td>Contextual disclosure</td>
              <td>Offering additional information without leaving the current context.</td>
              <td>Tooltips, Modals, Popovers</td>
            </tr>
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)
const progressiveDiscTypesMeaning = [
  { id: 1, item: 'Hierarchical disclosure', img:'', pairId: 1, column: 1 },
  { id: 2, item: 'Organising large amounts of information into manageable sections', img: '', pairId: 1, column: 2 },
  { id: 3, item: 'Contextual disclosure', img:'', pairId: 2, column: 1 },
  { id: 4, item: 'Offering supplementary information or actions that enhance the current user task.',img: '', pairId: 2, column: 2 },
  { id: 5, item: 'Conditional disclosure',  pairId: 3, img:'', column: 1 },
  { id: 6, item: 'Showing additional fields based on user choices.', img: '', pairId: 3, column: 2 },
  { id: 7, item: 'Sequential disclosure',  pairId: 4, img:'', column: 1 },
  { id: 8, item: 'Presenting fields in a sequence to keep users focused on completing one section at a time.', img: '', pairId: 4, column: 2 }
];

const progressiveDiscQuizData = [

    {
      type: 'intro',
      image: 'covers/progressive-disc-cover.png',
      title: 'Progressive Disclosure',
      keyInsights: ['Define progressive disclosure and explain its purpose in user interface design.', 'Describe the four main types of progressive disclosure: Sequential, Conditional, Hierarchical, and Contextual.','Provide examples of user interface patterns for each type of progressive disclosure.'],
    },

    {
        type: 'info',
        image: 'progressive-disc/progressive-disc-ux.png',
        text: 'UX Designers face a dilemma on one side we want to deliver a lot of features to our users and give them power, on the other, we want to make our interface as simple as possible. Progressive disclosure is one of the best ways to satisfy both of these conflicting requirements.',
    },
    {
        type: 'info',
        image: 'progressive-disc/progressive-disc-klarna.png',
        text: 'Progressive disclosure is a design technique that presents information and options gradually, preventing users from being overwhelmed by too much content at once. By revealing information step-by-step or based on user actions, it enhances usability and ensures a smoother user experience.',
        imageProduct: 'Klarna',
        imageProductSrc: 'https://www.klarna.com/',
        imageProductLogo: 'progressive-disc/klarna-logo.png',
    },
    {
        type: 'question',
        question: 'What is not the benefit of progressive disclosure?',
        image: '',
        options: ['Simplify the user interface', 'Reduce the cognitive load', 'Increase the efficiency of accessing information','Reduce the learning curve' ],
        correctAnswer: 'Increase the efficiency of accessing information',
        explanationImg: '',
        explanation: "A potential drawback of progressive disclosure is that it can sometimes frustrate experienced users who prefer to access all information and options quickly without going through multiple steps or interactions. This technique may slow down their workflow by requiring additional clicks or interactions to reveal the information they need."
      },
      {
        type: 'info',
        image: '',
        htmlContent: progressiveDiscTypes,
        text: '',
      },
      {
        type: 'question',
        question: 'Which type of disclosure is used for this onboarding flow?',
        image: 'progressive-disc/progressive-disc-babel.png',
        imageDesktopLarge: true,
        options: ['Conditional', 'Sequential', 'Hierarchical','Contextual' ],
        correctAnswer: 'Sequential',
        explanationImg: '',
        explanation: "Sequential disclosure involves dividing complex tasks into smaller, manageable steps, revealing each step in order upon completion of the previous one. This is pattern is commonly used for the onboarding flows.",
        imageProduct: 'Babbel',
        imageProductLogo: 'progressive-disc/babel-logo.png',
        imageProductSrc: 'https://www.babbel.com/',
      },
      {
        type: 'question',
        question: 'Which type of disclosure is used in this form?',
        image: 'progressive-disc/progressive-disc-conditional-disclosure.png',
        imageDesktopLarge: true,
        options: ['Hierarchical', 'Sequential', 'Contextual', 'Conditional' ],
        correctAnswer: 'Conditional',
        explanationImg: '',
        explanation: "Conditional disclosure involves revealing elements only when necessary based on user actions or inputs. Thereby, reducing the complexity of the screen.",
      },

      {
        type: 'question',
        question: 'Which type of disclosure is used here?',
        image: 'progressive-disc/progressive-disc-shop.png',
        options: ['Conditional', 'Sequential', 'Hierarchical','Contextual' ],
        correctAnswer: 'Contextual',
        explanationImg: '',
        explanation: "Contextual disclosure involves providing additional information or options directly within the current context without navigating away.",
        imageProduct: 'Shop',
        imageProductLogo: 'progressive-disc/shop-logo.png',
        imageProductSrc: 'https://shop.app/',
      },
      {
        type: 'question-matching-pairs',
        pairs: progressiveDiscTypesMeaning,
        explanation: ''
      },

      {
        type: 'summary',
        keyInsights: ['Progressive disclosure allows UX designers to deliver feature-rich interfaces while maintaining simplicity by gradually revealing information and options, thus preventing user overwhelm.', 'There are four main types of progressive disclosure: Sequential (step-by-step tasks), Conditional (elements revealed based on user actions), Hierarchical (layered information access), and Contextual (in-context information).', 'Understanding and identifying the appropriate type of progressive disclosure for different scenarios can significantly enhance usability, making interfaces more intuitive and efficient for users of varying experience levels.'],
      }
]  

export default progressiveDiscQuizData