import { Link } from "react-router-dom"

const Logo = () => {
    return (
        <Link to="/">
          
            <img className="logo" src={require(`../images/logo-image.png`)} />

        </Link>
        
    )
}

export default Logo